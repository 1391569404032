import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "loading-text-container"
};
export default {
  __name: 'index',
  props: ['isShowLoading'],
  setup: function setup(__props) {
    var props = __props;
    return function (_ctx, _cache) {
      var _component_van_skeleton = _resolveComponent("van-skeleton");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [__props.isShowLoading ? (_openBlock(), _createBlock(_component_van_skeleton, {
        key: 0,
        title: "",
        row: 3
      })) : _createCommentVNode("", true), !__props.isShowLoading ? _renderSlot(_ctx.$slots, "default", {
        key: 1
      }) : _createCommentVNode("", true)]);
    };
  }
};