import { createRouter, createWebHistory } from 'vue-router'

import { createRouterGuards } from './routerGuards'
import index from '@/views/index'
import overall from '@/views/overall'
import indicator from '@/views/indicator'
import position from '@/views/my/position'
import estimate from '@/views/estimate'
import contrast from '@/views/contrast'
import contrastDetail from '@/views/contrast/detail'
import fundDetail from '@/views/fundDetail'
import industryAnalysis from '@/views/industryAnalysis'
import search from '@/views/search'
import notFound from '@/views/notFound'
import login from '@/views/login'
import list from '@/views/list'

const routes = [
    {
        path: '/',
        component: index,
        meta: {
            title: '首页',
        },
    },
    {
        path: '/overall',
        component: overall,
        meta: {
            title: '大盘',
            auth: true,
        },
    },
    {
        path: '/indicator',
        component: indicator,
        meta: {
            title: '指标',
            // auth: true,
        },
    },
    {
        path: '/list/:type/:indicator',
        component: list,
        meta: {
            title: '产品列表',
            auth: true,
        },
    },
    {
        path: '/my',
        children: [
            {
                path: 'position',
                component: position,
                meta: {
                    title: '我的持仓',
                    auth: true,
                },
            },
        ],
    },
    // {
    //     path: '/estimate',
    //     component: estimate,
    //     meta: {
    //         title: '净值模拟',
    //         auth: true,
    //     },
    // },
    {
        path: '/contrast',
        component: contrast,
        meta: {
            title: '产品对比',
            auth: true,
        },
    },
    {
        path: '/contrastDetail',
        component: contrastDetail,
        meta: {
            title: '产品对比',
            auth: true,
        },
    },
    {
        path: '/search',
        component: search,
        meta: {
            title: '产品搜索',
        },
    },
    {
        path: '/fund-detail/:code',
        component: fundDetail,
        meta: {
            title: '产品详情',
            auth: true,
        },
        props: true,
    },
    {
        path: '/industryAnalysis',
        component: industryAnalysis,
        meta: {
            title: '行业分析',
        },
    },
    {
        path: '/login',
        component: login,
        meta: {
            title: '登录',
        },
    },
    {
        path: '/:catchAll(.*)',
        component: notFound,
        meta: {
            title: '404',
        },
    },

]

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export function setupRouter(app) {
    app.use(router)
    // 创建路由守卫
    createRouterGuards(router)
}

export default router