import axios from 'axios'
import router from '@/router'
import { showToast } from 'vant'
import * as storage from '@/utils/storage'
import { useProfileStore } from '@/store/modules/profile'

import { addPendingRequest, removePendingRequest } from './cancelRepeatRquest'
import { requestCacheInterceptor, responseCacheInterceptor } from './requestCache'

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	timeout: 60000,
})

// axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
// // 默认 post 请求，使用 application/json 形式
// axios.defaults.headers.post['Content-Type'] = 'application/json'

const errorHandler = (error) => {
	const { response } = error

	removePendingRequest(response)

	if (response) {
		showToast(`${response?.status}: ${response?.statusText || response?.data?.detail}`)

		if (response?.status == 401 && location.pathname != '/login') {
			storage.set(storage.PRE_ROUTE, `${location.pathname}${location.search}`)
			router.replace({
				path: '/login',
			})
		}
	}
	
	// console.error(error.message)

	return Promise.reject(error)
}

// 请求拦截
instance.interceptors.request.use(
	config => {
		const profileStore = useProfileStore()
		config.headers['x-authorization'] = `${profileStore.token}`

		addPendingRequest(config, axios)
		requestCacheInterceptor(config, axios)

		return config
	},
	error => {
		console.log(error)
		return Promise.reject(error)
	}
)

// 响应拦截
instance.interceptors.response.use(res => {
	// console.log(`响应拦截：${res.config.url}, ${JSON.stringify(res.config.data || res.config.params || '')}`)

	removePendingRequest(res)
	responseCacheInterceptor(res)

	if (res.status == 200) {
		return res.data
	} else {
		console.error('instance.interceptors.response')
		showToast(res.data.msg)
		return Promise.reject(res)
	}
}, errorHandler)


export default instance