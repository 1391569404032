import { createPinia } from 'pinia'
import piniaPluginPersist from 'pinia-plugin-persist'

const store = createPinia()

export function setupStore(app) {
    store.use(piniaPluginPersist)
    app.use(store)
}

export { store }