import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-ea8869e4"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "industry-analysis-container"
};
import bondFlow from '@/components/industry/bondFlow/index.vue';
import { reactive, onMounted, ref, getCurrentInstance } from 'vue';
export default {
  __name: 'index',
  setup: function setup(__props) {
    return function (_ctx, _cache) {
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(bondFlow)]);
    };
  }
};