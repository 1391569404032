import { useGlobalStore } from '@/store/modules/global'
import { useProfileStore } from '@/store/modules/profile'
import { storeToRefs } from 'pinia'
import instance from '@/utils/request'
import { isWeixin, config } from '@/utils/weixin'
import * as storage from '@/utils/storage'

export function createRouterGuards(router) {
    router.beforeResolve(async (to, from, next) => {
        // 同步交易日期
        const globalStore = useGlobalStore()
        if (!globalStore.tradeDayInfo) {
            await globalStore.initTradeDayInfo()
        }

        // if (isWeixin()) {
        //     const { data } = await instance.get(`${process.env.VUE_APP_COMMON_URL}/wx/signature`, {
        //         params: {
        //             url: `${location.protocol}//${location.host}${to.fullPath}`
        //         },
        //     })
        //     console.log(data)
    
        //     await config(data.timestamp, data.noncestr, data.signature)
        // }

        next()
    })


    router.beforeEach((to, from, next) => {
        if (to?.meta?.auth) {
            // 获取用户信息
            const profileStore = useProfileStore()
            if (!profileStore.token) {
                storage.set(storage.PRE_ROUTE, to.fullPath)
                next('/login')
            }
        }
        
        next()
    })

    router.afterEach((to, from, failure) => {
        document.title =(to?.meta?.title) || document.title

        const globalStore = useGlobalStore()
        globalStore.setPageTitle(document.title)
    })
}