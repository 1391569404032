import md5 from 'js-md5'

const pendingRequest = new Map()

export function addPendingRequest(config, axios) {
    if (!config.cancelRepeat) {
        return
    }
	const requestKey = md5(`${config.url}${JSON.stringify(config.params)}`)
	if (pendingRequest.has(requestKey)) {
		// console.log('存在请求未结束', config.url, JSON.stringify(config.params))
		config.cancelToken = new axios.CancelToken(cancel => {
			// cancel 函数的参数会作为 promise 的 error 被捕获
			cancel(`${config.url} ${JSON.stringify(config.params)} 请求已取消`)
		})
	} else {
		config.cancelToken =
			config.cancelToken ||
			new axios.CancelToken(cancel => {
				pendingRequest.set(requestKey, cancel)
			})
	}
}

// removePendingRequest：检查是否存在重复请求，若存在则取消已发的请求。
export function removePendingRequest(response) {
	if (response?.config?.cancelRepeat) {
		const requestKey = md5(`${response.config.url}${JSON.stringify(response.config.params)}`)
		// 判断是否有这个 key
		if (pendingRequest.has(requestKey)) {
			const cancelToken = pendingRequest.get(requestKey)
			cancelToken(requestKey)
			pendingRequest.delete(requestKey)
		}
	}
}