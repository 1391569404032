import { defineStore } from 'pinia'
import instance from '@/utils/request'

export const useDataStore = defineStore({
    id: 'data',
    state: () => ({
        smartMoneyMinute: [],
        smartMoneyDay: [],
        goldMinute: [],
        indexDay: {},
    }),
    getters: {},
    actions: {
        async initSmartMoneyMinute() {
            try {
                const { data } = await instance.get('/fof/industry/bx_minute', {
                    params: {},
                    cancelRepeat: true,
                    canCache: true,
                })
    
                this.smartMoneyMinute = data
            } catch {
                //
            }
        },
        async initSmartMoneyDay(startDate, endDate) {
            try {
                const { data } = await instance.get('/fof/industry/bx_history', {
                    params: {
                        start_date: startDate,
                        end_date: endDate,
                    },
                    cancelRepeat: true,
                })
    
                this.smartMoneyDay = data
            } catch {
                //
            }
        }, 
        async initGoldMinute() {
            try {
                const { data } = await instance.get('/fof/stock/minute', {
                    params: {
                        code: 'sh518880'
                    },
                    cancelRepeat: true,
                })
    
                this.goldMinute = data
            } catch {
                //
            }
        },
        async initIndexDay(code) {
            try {
                const { data } = await instance.get('/fof/index/day', {
                    params: {
                        code,
                    },
                    cancelRepeat: true,
                    canCache: true,
                })

                data.sort((a, b) => a.date - b.date)
    
                Object.assign(this.indexDay, {
                    [code]: data
                })
            } catch {
                //
            }
        },
    },
})