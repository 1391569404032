import moment from 'moment'

export const grid = {
    top: '5%',
    left: '0%',
    right: '5%',
    bottom: '60',
    containLabel: true
}

export const axisLabel = {
    margin: 15,
    showMaxLabel: true,
    showMinLabel: true,
}

export const markPoint = {
    data: [{
        type: 'max',
        label: {
            show: true,
        },
    },{
        type: 'min',
        label: {
            show: true,
        },
    }]
}

export const axisPointer = {
    lineStyle: {
        color: '#1989fa',
        width: 2
    },
    handle: {
        show: true,
        size: 35,
        color: '#1989fa'
    },
}