import { defineStore } from 'pinia'

export const useProfileStore = defineStore({
    id: 'profile',
    state: () => ({
        token: '',
    }),
    getters: {},
    actions: {
        setProfile(profile) {
            this.token = profile.token
        },
    },
    // 开启数据缓存
    persist: {
        enabled: true,
        strategies: [{
            key: 'profile',
            storage: localStorage,
            paths: ['token'],
        }],
    },
})