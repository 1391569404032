import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-c4e1f6f6"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "not-found-container"
};
export function render(_ctx, _cache) {
  var _component_van_empty = _resolveComponent("van-empty");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_empty, {
    description: "404"
  })]);
}