import md5 from 'js-md5'

const EXPIRE_TIME = 30000
const _CACHES = {}

export function requestCacheInterceptor(config, axios) {
    if (!config.canCache) {
        return
    }
    const requestKey = md5(`${config.url}${JSON.stringify(config.params)}`)
    let data = _CACHES[requestKey]
    // 判断缓存数据是否存在 存在的话 是否过期 没过期就返回
    if (data && new Date().getTime() - data.expire < EXPIRE_TIME) {
        config.cancelToken = new axios.CancelToken(cancel => {
            // console.log('存在缓存数据', config.url, data)
            // cancel 函数的参数会作为 promise 的 error 被捕获
            cancel(data)
        })
    }
}

export function responseCacheInterceptor(response) {
    if (!response?.config?.canCache) {
        return
    }
    const requestKey = md5(`${response.config.url}${JSON.stringify(response.config.params)}`)

    // 返回的code === 200 时候才会缓存下来
    if (response.status === 200) {
        let data = {
            type: 'CACHE',
            expire: new Date().getTime(),
            data: response.data.data
        }

        _CACHES[requestKey] = data
    }
}