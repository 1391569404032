import { defineStore } from 'pinia'
import { showLoadingToast, closeToast } from 'vant'
import { isWeixin } from '@/utils/weixin'
import instance from '@/utils/request'

export const useGlobalStore = defineStore({
    id: 'global',
    state: () => ({
        isLoading: false,
        isShowHome: false,
        isWeixin: false,
        pageTitle: '',
        tradeDayInfo: undefined,
    }),
    getters: {},
    actions: {
        setLoading(isLoading) {
            this.isLoading = isLoading

            if (isLoading) {
                showLoadingToast({
                    message: '加载中...',
                    forbidClick: true,
                  })
            } else {
                closeToast()
            }
        },
        setShowHome(isShowHome) {
            this.isShowHome = isShowHome
        },
        setWeixin() {
            this.isWeixin = isWeixin()
        },
        setPageTitle(pageTitle) {
            this.pageTitle = pageTitle
        },
        async initTradeDayInfo() {
            try {
                const { data } = await instance.get('/fof/devops/trade_day_info', {
                    params: {},
                    cancelRepeat: false,
                    canCache: true,
                })
    
                this.tradeDayInfo = {
                    isTradeDay: data.isTradeDay == '0' ? false : true,
                    currentDate: data.currentDate,
                }
            } catch {
                //
            }
        },
    },
})