import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "app-container"
};
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useGlobalStore } from '@/store/modules/global';
import { storeToRefs } from 'pinia';
export default {
  __name: 'App',
  setup: function setup(__props) {
    var router = useRouter();
    var route = useRoute();
    var globalStore = useGlobalStore();
    var _storeToRefs = storeToRefs(globalStore),
      isLoading = _storeToRefs.isLoading,
      isShowHome = _storeToRefs.isShowHome,
      isWeixin = _storeToRefs.isWeixin,
      pageTitle = _storeToRefs.pageTitle;
    globalStore.setWeixin();
    var go = function go(where) {
      if (where == 'home') {
        router.push('/');
      }
    };
    return function (_ctx, _cache) {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_router_view = _resolveComponent("router-view");
      var _component_van_back_top = _resolveComponent("van-back-top");
      var _component_van_tabbar_item = _resolveComponent("van-tabbar-item");
      var _component_van_tabbar = _resolveComponent("van-tabbar");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [!_unref(isWeixin) ? (_openBlock(), _createBlock(_component_van_nav_bar, {
        key: 0,
        title: _unref(pageTitle)
      }, null, 8, ["title"])) : _createCommentVNode("", true), _createVNode(_component_router_view, {
        style: {
          "padding-bottom": "1.33333rem"
        }
      }), _createVNode(_component_van_back_top), _createVNode(_component_van_tabbar, {
        route: ""
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_tabbar_item, {
            replace: "",
            to: "/",
            icon: "wap-home"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("首页")];
            }),
            _: 1
          }), _createVNode(_component_van_tabbar_item, {
            replace: "",
            to: "/overall",
            icon: "browsing-history"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("大盘")];
            }),
            _: 1
          }), _createVNode(_component_van_tabbar_item, {
            replace: "",
            to: "/indicator",
            icon: "column"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("指标")];
            }),
            _: 1
          }), _createVNode(_component_van_tabbar_item, {
            replace: "",
            to: "/my/position",
            icon: "balance-list"
          }, {
            default: _withCtx(function () {
              return [_createTextVNode("持仓")];
            }),
            _: 1
          })];
        }),
        _: 1
      })]);
    };
  }
};