import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createApp } from 'vue'
import { setupStore } from './store'
import { setupRouter } from './router'
import App from './App.vue'

import 'amfe-flexible'
import 'normalize.css'
import instance from '@/utils/request'

import 'vant/es/toast/style'
import 'vant/es/dialog/style'
import 'vant/es/notify/style'
import 'vant/es/image-preview/style'

import '@/style/main.less'

const app = createApp(App)

app.config.globalProperties.$http = instance

setupRouter(app)
setupStore(app)

app.mount('#app')
