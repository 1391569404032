import "core-js/modules/es.json.stringify.js";
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-31652594"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  class: "contrast-contain"
};
var _hoisted_2 = {
  class: "footer"
};
var _hoisted_3 = {
  class: "tips"
};
import { reactive, ref, onMounted, onBeforeUpdate } from 'vue';
import { useRouter, useRoute } from 'vue-router';
export default {
  __name: 'index',
  setup: function setup(__props) {
    var router = useRouter();
    var route = useRoute();
    var title = route.meta.title;
    var fundInfo = reactive({
      list: [],
      checkboxRefs: [],
      checked: []
    });
    var toggle = function toggle(index) {
      fundInfo.checkboxRefs[index].toggle();
    };
    if (localStorage.getItem('searchHistory')) {
      var searchHistory = localStorage.getItem('searchHistory');
      fundInfo.list = JSON.parse(searchHistory);
      fundInfo.checked = JSON.parse(searchHistory).map(function (o) {
        return o.code;
      });
    }
    var goSearch = function goSearch() {
      router.push('/search');
    };
    var goContrast = function goContrast() {
      //存对比基金进vuex
      localStorage.setItem('contrastFunds', JSON.stringify(fundInfo.checked));
      router.push('/contrastDetail');
    };
    return function (_ctx, _cache) {
      var _component_van_nav_bar = _resolveComponent("van-nav-bar");
      var _component_van_icon = _resolveComponent("van-icon");
      var _component_van_field = _resolveComponent("van-field");
      var _component_van_cell_group = _resolveComponent("van-cell-group");
      var _component_van_checkbox = _resolveComponent("van-checkbox");
      var _component_van_cell = _resolveComponent("van-cell");
      var _component_van_checkbox_group = _resolveComponent("van-checkbox-group");
      var _component_van_button = _resolveComponent("van-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_van_nav_bar, {
        title: _unref(title)
      }, null, 8, ["title"]), _createVNode(_component_van_cell_group, {
        inset: "",
        class: "search-ipt"
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_field, {
            placeholder: "请选择想要对比的产品",
            readonly: "",
            onClick: goSearch
          }, {
            button: _withCtx(function () {
              return [_createVNode(_component_van_icon, {
                name: "search",
                size: "18px"
              })];
            }),
            _: 1
          })];
        }),
        _: 1
      }), _createVNode(_component_van_checkbox_group, {
        modelValue: fundInfo.checked,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = function ($event) {
          return fundInfo.checked = $event;
        })
      }, {
        default: _withCtx(function () {
          return [_createVNode(_component_van_cell_group, {
            inset: "",
            class: "fund-list"
          }, {
            default: _withCtx(function () {
              return [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(fundInfo.list, function (item, index) {
                return _openBlock(), _createBlock(_component_van_cell, {
                  clickable: "",
                  key: item,
                  title: "".concat(item.name),
                  onClick: function onClick($event) {
                    return toggle(index);
                  }
                }, {
                  "right-icon": _withCtx(function () {
                    return [_createVNode(_component_van_checkbox, {
                      name: item.code,
                      "icon-size": "16px",
                      ref_for: true,
                      ref: function ref(el) {
                        return fundInfo.checkboxRefs[index] = el;
                      },
                      onClick: _cache[0] || (_cache[0] = _withModifiers(function () {}, ["stop"]))
                    }, null, 8, ["name"])];
                  }),
                  _: 2
                }, 1032, ["title", "onClick"]);
              }), 128))];
            }),
            _: 1
          })];
        }),
        _: 1
      }, 8, ["modelValue"]), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, "已经选择" + _toDisplayString(fundInfo.checked.length) + "只产品", 1), _createVNode(_component_van_button, {
        type: "primary",
        disabled: !fundInfo.checked.length,
        onClick: goContrast
      }, {
        default: _withCtx(function () {
          return [_createTextVNode("开始比对")];
        }),
        _: 1
      }, 8, ["disabled"])])]);
    };
  }
};