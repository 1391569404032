import { defineStore } from 'pinia'
import instance from '@/utils/request'

export const useIndicatorStore = defineStore({
    id: 'indicator',
    state: () => ({
        fund: {},
    }),
    getters: {},
    actions: {
        async initFundIndicator() {
            try {
                const { data } = await instance.get('/fof/indicator/list', {
                    params: {},
                    cancelRepeat: true,
                    canCache: true,
                })
    
                this.fund = data
            } catch {
                //
            }
        },
    },
})